import * as React from "react";

import CommercialInquiryModal from "./commercial-inquiry-modal";

import "../buttons.css";

// markup
const CommercialInquiry = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div>
      <CommercialInquiryModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      ></CommercialInquiryModal>
      <div className="bg-gray-100">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="font-extrabold tracking-tight text-gray-900 text-2xl md:text-4xl">
            <span className="block text-3xl md:text-4xl">Business Owner?</span>
            <span className="block clogreen text-xl md:text-2xl">
              We can tailor a plan for your needs.
            </span>
          </h2>
          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
            <div className="inline-flex rounded-md shadow">
              <button
                onClick={(e) => setIsOpen(true)}
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md clobutton"
              >
                Provide Details
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommercialInquiry;
