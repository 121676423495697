import React, { useEffect } from "react";

import AppStore from "../svg/app-store.svg";
import PlayStore from "../svg/play-store.svg";

import "fontsource-bangers";
import "./call-to-action.css";

import iosApp from "../images/ios-app.png";
import androidApp from "../images/android-app.png";

// markup
const CallToAction = ({ data }) => {
  useEffect(() => {
    let options = {
      root: document.querySelector("#scrollArea"),
      rootMargin: "0px",
      threshold: 0.25,
    };

    let observer = new IntersectionObserver((e) => {
      var ctaContainer = document.getElementById("cta-container");
      if (ctaContainer.offsetWidth < 860) {
        return;
      }
      console.log(ctaContainer.offsetWidth);
      if (e[0].intersectionRatio < 0.25) {
        return;
      }
      let android = document.getElementById("phone-android");
      let ios = document.getElementById("phone-iOS");

      android.classList.remove("hidden");
      ios.classList.remove("hidden");

      android.style.animation = "3s android-slide-up";
      ios.style.animation = "3s ios-slide-up";
    }, options);
    observer.observe(document.getElementById("cta-container"));
  });

  return (
    <div id="cta-container">
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 cta">
        <img id="phone-iOS" src={iosApp} className="hidden" alt="iOS App" />
        <img
          id="phone-android"
          src={androidApp}
          className="hidden"
          alt="Android App"
        />
        <div className="details">
          <h1 className="text-4xl font-bold mb-2 mt-20">Clean Laundry</h1>
          <p className="text-2xl font-semibold byline">
            Picked up, Washed, Folded, and Delivered to Your Doorstep.
          </p>

          <p className="mt-4">Plans starting at</p>
          <p>
            <span className="dollah">$</span>
            <span className="price">16</span>
            <span className="cents">49</span>
            <span className="term">a week</span>
          </p>
          <div className="flex">
            <a href="https://itunes.apple.com/us/app/cloto/id1175238344">
              <AppStore className="mb-2 mr-2 w-36"></AppStore>
            </a>
            <a href="https://play.google.com/store/apps/details?id=ca.cloto.userclient.android">
              <PlayStore className="mb-2 w-36"></PlayStore>
            </a>
          </div>
          <p>billed every 4 weeks | no commitment</p>
          <p className="mt-2">
            <a href="/plans-and-pricing" className="underline text-indigo-700">
              View Plans and Pricing
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default CallToAction;
