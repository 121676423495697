import * as React from "react";

import Math from "../images/math-dryclean.png";

import "./dry-cleaning.css";

import "@fontsource/roboto/900.css";

// markup
const DryCleaning = () => {
  return (
    <div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-3xl md:text-4xl text-gray-900 font-extrabold dry-clean-title">
            <a name="dry-cleaning">Dry Cleaning</a>
          </h1>
          <div class="rounded-md bg-yellow-50 p-4 mt-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <svg
                  class="h-5 w-5 text-yellow-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div class="ml-3">
                <h3 class="text-sm font-medium text-yellow-800 font-bold">
                  Dry cleaning is currently unavailable.
                </h3>
                <div class="mt-2 text-sm text-yellow-700">
                  <p>
                    Due to COVID-19, dry cleaning services are
                    unavailable. We hope to resume services soon.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:flex-col sm:align-center">
            <div className="relative self-center">
              <img src={Math} alt="Tote math" className="mb-4 ml-12" />
              <p className="text-gray-800 text-center">
                1 reusable garment bag included – $4.99 per additional bag.
              </p>
              <p className="text-gray-800 font-bold text-center">
                1 bag holds up to 6 items.
              </p>
              <p className="text-gray-800 text-center">
                *prices are subject to change.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <h1 className="text-2xl font-bold mt-2 mb-2 ml-5 text-gray-600">
                    Tops
                  </h1>
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Item
                        </th>
                        <th
                          scope="col"
                          className="text-right px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Subscribers
                        </th>
                        <th
                          scope="col"
                          className="text-right px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Non-Subscribers
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="bg-white">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Sweater
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $8.00
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $10.00
                        </td>
                      </tr>
                      <tr className="bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Laundered Shirt
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $3.25
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $3.75
                        </td>
                      </tr>
                      <tr className="bg-white">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Blouse
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $7.00
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $8.00
                        </td>
                      </tr>
                      <tr className="bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Shirt
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $5.50
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $6.50
                        </td>
                      </tr>
                      <tr className="bg-white">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Sweatshirt
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $7.00
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $8.00
                        </td>
                      </tr>
                      <tr className="bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Jersey
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $6.50
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $7.50
                        </td>
                      </tr>
                      <tr className="bg-white">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Robe
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $13.00
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $15.00
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col mt-8">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <h1 className="text-2xl font-bold mt-2 mb-2 ml-5 text-gray-600">
                    Bottoms
                  </h1>
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Item
                        </th>
                        <th
                          scope="col"
                          className="text-right px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Subscribers
                        </th>
                        <th
                          scope="col"
                          className="text-right px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Non-Subscribers
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="bg-white">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Skirt
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $7.00
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $8.00
                        </td>
                      </tr>
                      <tr className="bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Shorts
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $5.50
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $6.50
                        </td>
                      </tr>
                      <tr className="bg-white">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Jumpsuit
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $13.00
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $15.00
                        </td>
                      </tr>
                      <tr className="bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Leggings
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $6.00
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $7.00
                        </td>
                      </tr>
                      <tr className="bg-white">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Pants
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $5.50
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $6.50
                        </td>
                      </tr>
                      <tr className="bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Jeans
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $5.50
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $6.50
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col mt-8">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <h1 className="text-2xl font-bold mt-2 mb-2 ml-5 text-gray-600">
                    Dress Wear
                  </h1>
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Item
                        </th>
                        <th
                          scope="col"
                          className="text-right px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Subscribers
                        </th>
                        <th
                          scope="col"
                          className="text-right px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Non-Subscribers
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="bg-white">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Dress
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $14.00
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $15.00
                        </td>
                      </tr>
                      <tr className="bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Dress Children's
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $5.50
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $6.50
                        </td>
                      </tr>
                      <tr className="bg-white">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Tie
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $5.00
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $6.00
                        </td>
                      </tr>
                      <tr className="bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Gown
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $24.00
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $30.00
                        </td>
                      </tr>
                      <tr className="bg-white">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          2 Piece Skirt Suit
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $15.00
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $16.00
                        </td>
                      </tr>
                      <tr className="bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Suit Jacket
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $9.00
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $10.00
                        </td>
                      </tr>
                      <tr className="bg-white">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Tuxedo
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $20.00
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $22.00
                        </td>
                      </tr>
                      <tr className="bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          2 Piece Suit
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $15.00
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $16.00
                        </td>
                      </tr>
                      <tr className="bg-white">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Vest
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $7.00
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $8.00
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col mt-8">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <h1 className="text-2xl font-bold mt-2 mb-2 ml-5 text-gray-600">
                    Outerwear
                  </h1>
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Item
                        </th>
                        <th
                          scope="col"
                          className="text-right px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Subscribers
                        </th>
                        <th
                          scope="col"
                          className="text-right px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Non-Subscribers
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="bg-white">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Jacket Down
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $37.00
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $40.00
                        </td>
                      </tr>
                      <tr className="bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Jacket Fur Hood
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $65.00
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $68.00
                        </td>
                      </tr>
                      <tr className="bg-white">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Jacket Wind
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $15.00
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $17.00
                        </td>
                      </tr>
                      <tr className="bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Coat
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $20.00
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $24.00
                        </td>
                      </tr>
                      <tr className="bg-white">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Gloves (Each)
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $3.00
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $4.00
                        </td>
                      </tr>
                      <tr className="bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Shawl/Scarf
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $6.00
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $7.00
                        </td>
                      </tr>
                      <tr className="bg-white">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Lab Coat
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $14.00
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $15.00
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col mt-8 mb-16">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <h1 className="text-2xl font-bold mt-2 mb-2 ml-5 text-gray-600">
                    Beddings and Linens
                  </h1>
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Item
                        </th>
                        <th
                          scope="col"
                          className="text-right px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Subscribers
                        </th>
                        <th
                          scope="col"
                          className="text-right px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Non-Subscribers
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="bg-white">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Tablecloth
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $15.00
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $16.00
                        </td>
                      </tr>
                      <tr className="bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Curtains Light
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $30.00
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $32.00
                        </td>
                      </tr>
                      <tr className="bg-white">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Bedding Down Comforter
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $39.00
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $42.00
                        </td>
                      </tr>
                      <tr className="bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Bedding Comforter
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $30.00
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $34.00
                        </td>
                      </tr>
                      <tr className="bg-white">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Bedding Bed Sheet
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $14.00
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $15.00
                        </td>
                      </tr>
                      <tr className="bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Bedding Pillow
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $20.00
                        </td>
                        <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          $21.00
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DryCleaning;
